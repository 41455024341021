<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <div class="head-box">
                    <div class="table-operator">
                        <a-input v-model="form.phone" style="width:210px;" class="margin_right20" placeholder="请输入用户手机号" />
                        <a-button type="primary" class="margin_right20" @click="queryClick">查询</a-button>
                    </div>
                </div>
                <div class="contant-box">
                    <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.userNo"  :loading="tableLoading" :pagination="false" :scroll="{x: 2100}">
                        <template slot="buyCourse" slot-scope="text, record">
                            <span>{{ !record.buyCourse ? '--' : record.buyCourse }}</span>
                        </template>
                        <template slot="firstPlayVideo" slot-scope="text, record">
                            <span>{{ record.firstPlayVideo == '0001-01-01 00:00:00' ? '--' : record.firstPlayVideo  }}</span>
                        </template>
                        <template slot="courseName" slot-scope="text, record">
                            <span>内容名：{{ record.courseName }} <br> 课讲名：{{ record.lessonName }}</span>
                        </template>
                        <template slot="action" slot-scope="text, record" v-if="isShowDetail">
                            <a class="btn-edit" @click="onEdit(record)">详情</a>
                        </template>
                    </a-table>
                </div>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-if="tableList.length>0"/>
            </div>
        </a-spin>
    
        <!--    导出弹框    -->
        <a-modal v-model="visibleShow" title="导出">
            <template #footer>
                <div style="display: flex;justify-content: flex-end">
                    <a-button @click="visibleShow = false" class="margin_right20">取消</a-button>
                    <download-excel
                        :data="exportList"
                        :fields="tableDataName"
                        name="用户播放数据.xls">
                        <a-button type="primary" @click="visibleShow = false">确定</a-button>
                    </download-excel>
                </div>
            </template>
            <p>
                <a-icon type="info-circle" :style="{fontSize: '16px', color: '#FF8200'}"/>
                <span style="margin-left: 10px">确定要导出此数据吗？</span>
            </p>
        </a-modal>
        
        <router-view/>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {codeFn} from "@/utils/tools";
import {SearchCourse, UserPlayList} from "@/request/api/userManage";
import Treeselect from '@/components/channel/treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    components: {MyPagination, Treeselect},
    created() {
        if (codeFn("/adminv2/playvideo/userPlayList")) this.isShowExport = true;
        if (codeFn("/adminv2/playvideo/userStudyBehavior")) this.isShowDetail = true;
    },
    data() {
        return {
            name:'',
            spinning: false, // 全局loading
            tableLoading: false,
            visibleShow:false,
            isDateShow:true,
    
            registerVal:[],

            form:{
                channel:[],
                key:undefined,
                phone:'',
                registerStart:'',
                registerEnd:'',
            },

            columns: [
                {
                    title: "用户手机",
                    dataIndex: "userName",
                    key: "userName",
                    fixed: 'left',
                },
                {
                    title: "用户姓名",
                    dataIndex: "nickName",
                    key: "nickName",
                },
                {
                    title: "所属渠道",
                    dataIndex: "newestAgentName",
                    key: "newestAgentName",
                },
                {
                    title: "注册日期",
                    dataIndex: "registerTime",
                    key: "registerTime",
                },
                {
                    title: "已有权益内容",
                    dataIndex: "buyCourse",
                    key: "buyCourse",
                    scopedSlots: {customRender: "buyCourse"},
                },
                {
                    title: "已播内容数",
                    dataIndex: "totalStudyCourse",
                    key: "totalStudyCourse",
                    scopedSlots: {customRender: "totalStudyCourse"},
                },
                {
                    title: "首次播放时间",
                    dataIndex: "firstPlayVideo",
                    key: "firstPlayVideo",
                    scopedSlots: {customRender: "firstPlayVideo"},
                },
                {
                    title: "最近播放时间",
                    dataIndex: "lastPlayTime",
                    key: "lastPlayTime",
                },
                {
                    title: "最近播放内容",
                    dataIndex: "courseName",
                    key: "courseName",
                    scopedSlots: {customRender: "courseName"},
                },
                {
                    title: "最近播放内容课讲学习进度",
                    dataIndex: "viewProgress",
                    key: "viewProgress",
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    width: "80px",
                    fixed: 'right',
                    scopedSlots: {customRender: "action"},
                },
            ],
            tableList: [],

            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0, // 列表的总条数
    
            isShowExport: false, // 导出是否隐藏
            isShowDetail: false, // 详情是否隐藏
            
            options:[],
    
            exportList:[],
            tableDataName: {
                "用户手机号": "userName",
                "用户姓名": "nickName",
                "所属渠道": "newestAgentName",
                "注册日期": "registerTime",
                "已有权益内容": "buyCourse",
                "已播内容数":"totalStudyCourse",
                "首次播放时间": {
                    field: "firstPlayVideo",
                    callback: value => {
                        return value == '0001-01-01 00:00:00' ? '--' : value
                    }
                },
                "最近播放时间": "lastPlayTime",
                "最近播放内容名": "courseName",
                "最近播放课讲名": "lessonName",
                "最近播放内容课讲学习进度":"viewProgress",
            },
        };
    },
    mounted() {
    },
    methods: {
        // 注册时间
        onChangeRegister(date, dateString){
            if (dateString[0] != '') {
                this.form.registerStart = dateString[0];
                this.form.registerEnd = dateString[1];
            } else {
                this.form.registerStart = "";
                this.form.registerEnd = "";
            }
        },
    
        // 搜索·用户归属
        treeDataChange(data){
            this.form.channel = data
            this.isDateShow = true
        },
        
        // 点击查询
        queryClick(){
            this.tableList = []
            this.pageNo = 1
            this.getTagList()
        },
        
        // 点击重置按钮
        resetClick(){
            this.form.phone = ''
            this.form.key = undefined
            this.form.channel = []
            this.registerVal = []
            this.form.registerStart = ''
            this.form.registerEnd = ''
            this.isDateShow = false
            this.getTagList()
        },
    
        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },
        
        // 点击导出
        exportClick(){
            UserPlayList({
                phone:this.form.phone,
                channel:this.form.channel.length>0 ? this.form.channel.toString() : '',
                courseName:this.form.key == undefined ? '' : this.form.key,
                registerStart:this.form.registerStart,
                registerEnd:this.form.registerEnd,
                pageNo: this.pageNo,
                pageSize: 0
            }).then((res) => {
                if (res.code === 200) {
                    this.exportList = res.data.list
                    if(this.exportList.length>0){
                        this.visibleShow = true
                    }else{
                        this.$message.warning('暂无导出数据')
                    }
                }
            });
        },
        
        // 列表数据
        getTagList () {
            if (!this.form.phone) {
                this.tableList = []
                return
            }
            this.tableLoading = true;
            UserPlayList({
                phone:this.form.phone,
                channel:this.form.channel.length>0 ? this.form.channel.toString() : '',
                courseName:this.form.key,
                registerStart:this.form.registerStart,
                registerEnd:this.form.registerEnd,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.list;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },

        // 编辑
        onEdit(record) {
            this.$router.push(`/userManage/playDataList/ShortTermlist/${record.userNo}`);
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course");
        },
    
        // 课程/商品关键字
        handleSearch(val){
            if(!val){
                this.options = []
            }else{
                SearchCourse({
                    word: val,
                }).then((res) => {
                    if (res.code === 200) {
                        this.options = []
                        let options = res.data
                        for(let i = 0; i < options.length; i++){
                            let obj = {}
                            obj.label = options[i].name
                            obj.value = options[i].name
                            this.options.push(obj)
                        }
                    }
                });
            }
        },
    }
};
</script>

<style lang="less" scoped>
#course_category {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.head-box {
    display: flex;
    margin-bottom: 30px;
}

.avatar {
    width: 160px;
    height: 80px;
    object-fit: contain;
}

.btn-edit {
    margin-right: 10px;
}
.table-operator{
    display: flex;
}
/deep/.vue-treeselect__control{
    width: 210px;
}
/deep/ .ant-input{
    min-height: 36px !important;
}
/deep/ .ant-select-selection--single{
    min-height: 36px !important;
}
/deep/.ant-select-selection__rendered{
    line-height: 35px;
}
</style>
